.tableHeader th {
  color: #9e9e9e !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  padding: 0 0rem 1rem !important;
  white-space: nowrap !important;
  text-align: left !important;
}

$colorLight: #fafafa;
$colorDark: #252529;
$colorLightGrey: #eeeeee;
$colorGrey: #bdbdbd;

$colorWhite: #fff;

$colorDarkGreen: #2e7d32;
$colorGreen: #4caf50;
$colorLightGreen: #4dd0e1;

$color1: #9575cd;
$color2: #7986cb;
$color2: #f48fb1;
$color3: #9575cd;
$color4: #4fc3f7;

$colorWarning: #ffa000;
$colorOff: #bf360c;

$layoutWidth: 980px;
$standardPadding: 20px;
$layoutBorderRadius: 5px;

$sizeDefault: 1em;
$sizeLarge: 1.25em;
$sizeXLarge: 1.5em;
$sizeXXLarge: 2em;

$col1Width: 240px;
$col2Width: 145px;
$col3Width: 190px;
$col4Width: 150px;
$col5Width: 170px;
$col6Width: 30px;

.container {
  margin: 0 auto;
  margin-top: 50px;
  width: $layoutWidth;
}

.editorList.task-items {
  li.item {
    display: flex;
    align-items: center;
    margin: $standardPadding 0;
    padding: $standardPadding;
    background: $colorWhite;
    border-radius: $layoutBorderRadius;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

    &.type1 .task {
      .icon {
        background: $color1;
      }
    }

    &.type2 .task {
      .icon {
        background: $color2;
      }
    }

    &.type3 .task {
      .icon {
        background: $color3;
      }
    }

    &.type4 .task {
      .icon {
        background: $color4;
      }
    }

    .task {
      display: flex;
      align-items: center;
      width: $col1Width;

      .icon {
        background: $colorGrey;
        width: 50px;
        height: 50px;
        border-radius: $layoutBorderRadius;
      }

      .name {
        background: $colorLightGrey;
        margin-left: $standardPadding;
        width: 180px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $sizeDefault;
      color: $colorDarkGreen;
      width: $col2Width;
      margin-left: $standardPadding * 1.5;

      .icon {
        background: $colorDarkGreen;
        margin-right: $standardPadding/2;
        width: 14px;
        height: 14px;
        border-radius: 50%;

        &.risk {
          background: red;
        }

        &.warning {
          background: $colorWarning;
        }

        &.off {
          background: $colorOff;
        }
      }
    }

    .progress {
      width: $col3Width;

      progress {
        display: block;
        margin-left: 0;
        -webkit-appearance: none;
        height: 12.5px;
        width: $col3Width * 0.75;
      }

      progress::-webkit-progress-bar {
        background-color: $colorLightGrey;
        border-radius: 5px;
      }

      ::-webkit-progress-value {
        background-color: $colorLightGreen;
        border-radius: 5px;
      }
    }

    .dates {
      width: $col4Width;
    }

    .dates,
    .priority {
      .bar {
        background: $colorLightGrey;
        width: 100px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .priority {
      width: $col5Width * 0.85;

      .bar {
        background: #ffcdd2;
      }
    }

    .user {
      width: $col6Width;

      img {
        border-radius: 50%;
      }
    }
  }
}
.editorSubList {
  display: flex;
}
.editorSubList.task-items {
  li.item {
    display: flex;
    align-items: center;
    // margin: $standardPadding 0;
    margin-top: -10px;
    margin-right: 2em;
    width: 48%;
    padding: $standardPadding;
    background: $colorWhite;
    border-radius: $layoutBorderRadius;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

    &.type1 .task {
      .icon {
        background: $color1;
      }
    }

    &.type2 .task {
      .icon {
        background: $color2;
      }
    }

    &.type3 .task {
      .icon {
        background: $color3;
      }
    }

    &.type4 .task {
      .icon {
        background: $color4;
      }
    }

    .task {
      display: flex;
      align-items: center;
      width: $col1Width;

      .icon {
        background: $colorGrey;
        width: 50px;
        height: 50px;
        border-radius: $layoutBorderRadius;
      }

      .name {
        background: $colorLightGrey;
        margin-left: $standardPadding;
        width: 180px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $sizeDefault;
      color: $colorDarkGreen;
      width: $col2Width;
      margin-left: $standardPadding * 1.5;

      .icon {
        background: $colorDarkGreen;
        margin-right: $standardPadding/2;
        width: 14px;
        height: 14px;
        border-radius: 50%;

        &.risk {
          background: red;
        }

        &.warning {
          background: $colorWarning;
        }

        &.off {
          background: $colorOff;
        }
      }
    }

    .progress {
      width: $col3Width;

      progress {
        display: block;
        margin-left: 0;
        -webkit-appearance: none;
        height: 12.5px;
        width: $col3Width * 0.75;
      }

      progress::-webkit-progress-bar {
        background-color: $colorLightGrey;
        border-radius: 5px;
      }

      ::-webkit-progress-value {
        background-color: $colorLightGreen;
        border-radius: 5px;
      }
    }

    .dates {
      width: $col4Width;
    }

    .dates,
    .priority {
      .bar {
        background: $colorLightGrey;
        width: 100px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .priority {
      width: $col5Width * 0.85;

      .bar {
        background: #ffcdd2;
      }
    }

    .user {
      width: $col6Width;

      img {
        border-radius: 50%;
      }
    }
  }
  li.subItem {
    display: flex;
    align-items: center;
    // margin: $standardPadding 0;
    margin-top: -10px;
    margin-right: 2em;
    // width: 48%;
    padding: $standardPadding;
    background: $colorWhite;
    border-radius: $layoutBorderRadius;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);

    &.type1 .task {
      .icon {
        background: $color1;
      }
    }

    &.type2 .task {
      .icon {
        background: $color2;
      }
    }

    &.type3 .task {
      .icon {
        background: $color3;
      }
    }

    &.type4 .task {
      .icon {
        background: $color4;
      }
    }

    .task {
      display: flex;
      align-items: center;
      width: $col1Width;

      .icon {
        background: $colorGrey;
        width: 50px;
        height: 50px;
        border-radius: $layoutBorderRadius;
      }

      .name {
        background: $colorLightGrey;
        margin-left: $standardPadding;
        width: 180px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .status {
      display: flex;
      align-items: center;
      font-size: $sizeDefault;
      color: $colorDarkGreen;
      width: $col2Width;
      margin-left: $standardPadding * 1.5;

      .icon {
        background: $colorDarkGreen;
        margin-right: $standardPadding/2;
        width: 14px;
        height: 14px;
        border-radius: 50%;

        &.risk {
          background: red;
        }

        &.warning {
          background: $colorWarning;
        }

        &.off {
          background: $colorOff;
        }
      }
    }

    .progress {
      width: $col3Width;

      progress {
        display: block;
        margin-left: 0;
        -webkit-appearance: none;
        height: 12.5px;
        width: $col3Width * 0.75;
      }

      progress::-webkit-progress-bar {
        background-color: $colorLightGrey;
        border-radius: 5px;
      }

      ::-webkit-progress-value {
        background-color: $colorLightGreen;
        border-radius: 5px;
      }
    }

    .dates {
      width: $col4Width;
    }

    .dates,
    .priority {
      .bar {
        background: $colorLightGrey;
        width: 100px;
        height: 25px;
        border-radius: $layoutBorderRadius * 3;
      }
    }

    .priority {
      width: $col5Width * 0.85;

      .bar {
        background: #ffcdd2;
      }
    }

    .user {
      width: $col6Width;

      img {
        border-radius: 50%;
      }
    }
  }
}
.QA-image {
  height: 100%;
  width: 100%;
  object-fit: contain;
  // margin-top: 2em;
  // margin-bottom: 2em;
  position: relative;
}
