.userInputFields {
  margin-right: 1em;
  margin-bottom: 1em !important;
}

.userInputLabel {
  color: #8f8f8f;
  /* margin-left: 1em !important; */
  margin-right: 1em !important;
}

.dropdownButton {
  width: 100%;
}

/* this just increase the size of the child, since this one is accessible */
.dropdownButton div {
  width: 100%;
}

.addStyleGuideButton {
  margin-top: 3%;
  margin-left: 0.5em;
}

.accountDetailLabel {
  width: 7em;
}
