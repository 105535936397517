.order-status-button {
  margin-left: 1em;
  height: 2em;
  padding-top: initial !important;
  padding-bottom: initial !important;
}
.editor-button-group {
  position: relative;
  right: 0px;
  text-align: right;
}

.list-group-item {
  border: none !important;
  padding-left: 0 !important;
  font-family: Poppins, Helvetica, 'sans-serif';
}
.list-group-item div {
  margin-left: 0 !important;
}
.QA-margins {
  margin-left: -20%;
  margin-right: -15%;
}
.carousel-index {
  z-index: 101 !important;
}
.carousel-buttons {
  position: absolute;
  height: 200px;
  top: 45%;
  padding: 0 !important;
  width: 50px;
  color: white;
}
.coursel-btn-rigth {
  left: 100%;
}
.coursel-btn-left {
  right: 100%;
}
.carousel-index-buttons {
  color: white;
  display: flex;
  justify-content: center !important;
  margin-top: -1%;
}
.selectors {
  position: relative;
  display: block;
  justify-content: center;
}
.order-status-list-items {
  cursor: pointer;
}
.order-status-list-items:hover {
  cursor: pointer;
}
