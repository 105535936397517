.authentication-title {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: center;
  color: rgb(25, 118, 210);
}

.authentication-box {
  @extend .bg-white;
  @extend .border;
  @extend .border-secondary;
  @extend .py-10;
  @extend .px-10;
  @extend .rounded;
  @extend .justify-content-center;
}
