.snippet {
  position: absolute;
  width: 100vh;
  left: 27%;
  bottom: 40%;
  display: flex;
  justify-content: center;
}
.dot-container-loading-text {
  position: absolute;
  width: 100vh;
  left: 27%;
  bottom: 43%;
  display: flex;
  justify-content: center;
}
.dot-container {
  display: flex;
}
.dot-pulse {
  margin-left: 20px;
  margin-right: 20px;
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #00c853;
  color: #00c853;
  box-shadow: 9999px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0.5s;
}

.dot-pulse-yellow {
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #fff64f;
  color: #fff64f;
  box-shadow: 9984px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 0s;
}

.dot-pulse-red {
  position: relative;
  left: -9999px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
  background-color: #ff5131;
  color: #ff5131;
  box-shadow: 10014px 0 0 -5px;
  animation: dot-pulse 1.5s infinite linear;
  animation-delay: 1s;
}

@keyframes dot-pulse-before {
  0% {
    box-shadow: 9984px 0 0 -5px;
  }

  30% {
    box-shadow: 9984px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9984px 0 0 -5px;
  }
}

@keyframes dot-pulse {
  0% {
    box-shadow: 9999px 0 0 -5px;
  }

  30% {
    box-shadow: 9999px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 9999px 0 0 -5px;
  }
}

@keyframes dot-pulse-after {
  0% {
    box-shadow: 10014px 0 0 -5px;
  }

  30% {
    box-shadow: 10014px 0 0 2px;
  }

  60%,
  100% {
    box-shadow: 10014px 0 0 -5px;
  }
}
