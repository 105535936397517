.multipleImage div {
  height: 150px;
  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}
.multipleImage .loadedImage {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: cover;
}
.multipleImage .loadingImage {
  position: absolute;
  right: 42%;
  top: 40%;
}
.multipleImage button {
  position: absolute;
  padding-left: 0;
  padding-right: 0;
  right: 9px;
  object-fit: contain;
}
.multipleImage button i {
  font-weight: 100;
  font-size: 1.5em;
}
.imageType {
  position: absolute !important;
  top: -2.5em;
  left: 1em;
  padding-left: 0;
  padding-right: 0;
  right: 1px;
  margin-top: 6px !important;
  min-width: 90%;
  max-height: 2em;
  overflow-x: hidden;
  overflow-y: hidden;
}

.imageApproval {
  position: absolute;
  display: flex;
  padding-top: 0;
  padding-bottom: 0px;
  margin: auto;
  bottom: 0;
  right: 33%;
}
.multipleImageUser div {
  height: 150px;
  width: 25%;
  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}
.multipleImageUser .loadedImage {
  max-width: 100%;
  min-width: 100%;
  height: 100%;
  object-fit: contain;
  background: black;
}
.imageSize {
  border-radius: 10%;
  object-fit: contain;
  background: black;
}
.multipleImageUser .loadingImage {
  position: absolute;
  right: 42%;
  top: 40%;
}
.multipleImageUser button {
  position: absolute;
  /* left: 0; */
  padding-left: 0;
  padding-right: 0;
  right: 9px;
  object-fit: contain;
  /* z-index: 1000; */
}
.multipleImageUser button i {
  font-weight: 100;
  font-size: 1.5em;
}
.badge-status {
  font-size: 14px !important;
  margin-left: 1rem;
}
