.pagination {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0;
}

.page-item {
  margin-right: $pagination-item-space;
  display: flex;
  &:last-child {
    margin-right: 0;
  }

  .page-link {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $btn-border-radius;
    height: $pagination-item-height;
    min-width: $pagination-item-height;
    font-weight: $pagination-font-weight;
    font-size: $pagination-font-size;

    li {
      font-size: $pagination-icon-font-size;
    }
  }

  &:focus:not(.disabled) {
    .page-link {
      color: $pagination-focus-color;
    }
  }

  &:hover:not(.disabled) {
    .page-link {
      color: $pagination-hover-color;
    }
  }
  &.disabled {
    .page-link {
      color: $pagination-disabled-color;
    }
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .page-item {
    margin-right: $pagination-item-space-tablet-and-mobile;

    &:last-child {
      margin-right: 0;
    }
  }
}
