.imageHover {
  width: 100%;
  object-fit: contain;
  transition: transform 0.2s !important;
  padding: 0 0 0 0 !important;
}

.imageHover:hover {
  cursor: pointer;
  object-fit: contain;
  -webkit-transform: scale(1.1);
  transform: scale(1.1) !important;
}
