.event-header {
  cursor: pointer;
}

.event-header:hover {
  background-color: #f5f5f5;
}

.event-item-clickable {
  cursor: pointer;
}
.event-item-clickable:hover {
  background-color: #f5f5f5;
}
