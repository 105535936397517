.clipboard-text {
  cursor: pointer;
}

.clipboard-text .hover-text {
  visibility: hidden;
  background-color: black;
  color: #fff;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
}

.clipboard-text:hover .hover-text {
  visibility: visible;
}
